<template>
  <main class="main orgs">
    <div class="container">
      <div class="main__inner">
        <section class="section section__filter">
          <h2>Учреждения и организации</h2>
          <div class="filter__form-change-wrap">
            <form
              @submit.prevent="submitFilterForm"
              ref="filterForm"
              class="filter__form filter__form-change"
              autocomplete="off"
            >
              <div class="search filter search__long">
                <div class="search__input">
                  <label for="docs_search" class="search__label">
                    Название учреждения или организации для поиска
                  </label>
                  <div class="search__input__wrap">
                    <input v-model="form.title.value" id="docs_search" type="text" maxlength="255" />
                    <button v-if="form.title.value" type="button" @click="resetSearchInput">
                      <CloseIcon />
                    </button>
                  </div>
                </div>
                <div class="filter__item">
                  <label>Сфера деятельности</label>
                  <Multiselect
                    track-by="id"
                    label="title"
                    v-model="form.category_id.value"
                    :options="sortedMainCategories"
                    placeholder="Все сферы"
                    selectLabel="Выбрать ↵"
                    deselectLabel="Удалить ↵"
                    selectedLabel="Выбрано"
                    :searchable="false"
                    :allow-empty="true"
                  >
                    <span slot="noOptions">Список пуст</span>
                  </Multiselect>
                </div>
                <div class="search__btn search__btn--with-clear">
                  <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
                  <button type="submit" class="btn-blue_dark" :disabled="loading">
                    <LoadingIndicator v-if="loading" title="Загрузка" />
                    <template v-else>Найти</template>
                  </button>
                </div>
              </div>
            </form>
            <a
              @click.prevent="changeFilterForm"
              class="filter__change-btn"
              :class="{ hide__btn: isFilterVisible }"
              href="#"
            >
              <span v-if="isFilterVisible">Скрыть фильтр</span>
              <span v-else>Поиск и фильтр по учреждениям</span>
              <ArrowDownIcon />
            </a>
          </div>
        </section>
        <section class="section section__documents orgs__list section-pb48">
          <div v-if="orgs && orgs.data && orgs.data.length">
            <div v-for="(item, index) in orgs.data" :key="index" class="orgs__link-wrap">
              <router-link
                :to="{
                  name: 'localOrganization',
                  params: { id: item.id },
                }"
                v-if="isInstitution(item)"
                class="orgs__link"
              >
                <span class="orgs__link-title" v-if="item.title">
                  {{ item.title }}
                </span>
                <span class="orgs__link-category" v-if="item.category && item.category.title">
                  {{ item.category.title }}
                </span>
              </router-link>
              <a v-else :href="item.url | url" class="orgs__link">
                <span class="orgs__link-title" v-if="item.title">
                  {{ item.title }}
                </span>
                <span class="orgs__link-category" v-if="item.category && item.category.title">
                  {{ item.category.title }}
                </span>
              </a>
            </div>

            <PaginationComponent
              v-if="page !== null && total !== null && total > maxOnPage"
              :total="Math.ceil(total / maxOnPage)"
              @change="paginate"
              :page="page"
            />
          </div>
          <p v-else-if="!$store.state.filterData" class="_item__text">Учреждений и организаций нет</p>
          <p v-else-if="$store.state.filterData.get('title')" class="_item__text">
            По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
          </p>
          <p v-else class="_item__text">По вашему запросу ничего не найдено</p>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import Multiselect from "vue-multiselect";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import PaginationComponent from "@/components/Pagination.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "LocalOrgs",
  async asyncData({ store }) {
    await store.dispatch("POST_ORGS_FILTER", { data: {} });
  },
  data() {
    return {
      page: 1,
      loading: false,
      // total: 1000,
      // maxOnPage: 25,
      isFilterVisible: false,
      form: {
        title: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
      },
    };
  },
  computed: {
    orgs() {
      return this.$store.state.orgs_page;
    },
    total() {
      return this.$store.state?.orgs_page.total;
    },
    maxOnPage() {
      return this.$store.state?.orgs_page.per_page;
    },
    mainCategories() {
      if (this.$store.state.main_categories && this.$store.state.main_categories.length) {
        return this.$store.state.main_categories.filter((item) => {
          return item.status.code === this.$store.state.status.CODE_ACTIVE;
        });
      }
      return [];
    },
    sortedMainCategories() {
      let newCategories = [];
      this.mainCategories.forEach((item) => {
        newCategories.push(item);
        if (item.child_categories && item.child_categories.length) {
          newCategories = newCategories.concat(
            item.child_categories.filter(
              (child) => child.status.code === this.$store.state.status.CODE_ACTIVE
            )
          );
        }
      });
      return newCategories;
    },
  },
  methods: {
    getUrl(item) {
      if (this.isInstitution(item)) {
        return {
          name: "localOrganization",
          params: { id: item.id },
        };
      }
      return this.$options.filters.url(item.url);
    },
    isInstitution(item) {
      return (item.type && item.type.code === this.$store.state.type.CODE_INSTITUTIONS) || !item.url;
    },
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    paginate(e) {
      this.page = e;

      let pageUrl = this.orgs.first_page_url.slice(0, -1) + this.page;
      this.$store
        .dispatch("POST_ORGS_FILTER", {
          data: this.$store.state.filterData ? this.$store.state.filterData : {},
          url: pageUrl,
        })
        .then(() => {
          this.loading = false;
          window.scrollTo(0, 0);
        })
        .catch((response) => {
          this.loading = false;
          console.error(response.data);
        });
    },
    /**
     * Фильтр учреждений и организаций
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        this.page = 1;
        const fd = new FormData();
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        Object.keys(this.form).forEach((key) => {
          if (key === "category_id") {
            if (this.form[key].value) fd.append(key, this.form[key].value.id);
          } else {
            if (this.form[key].value !== null) fd.append(key, this.form[key].value);
          }
        });
        this.$store.state.filterData = fd;
        await this.$store
          .dispatch("POST_ORGS_FILTER", { data: fd })
          .then((response) => {
            this.loading = false;
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.paginate(1);
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
  components: {
    PaginationComponent,
    Multiselect,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/parts/documents.styl"

.orgs {
  &__list {
    display flex
    flex-direction column
  }

  &__link-wrap {
    &:not(:last-child) {
      border-bottom 1px solid var(--color_gray_divider)
    }
  }

  &__link {
    width: 100%;
    padding 16px 0
    display: flex;
    justify-content space-between
    gap 16px
    +below(768px) {
      flex-direction column-reverse
      gap 4px
      padding 12px 0
    }

    &:hover .orgs__link-title {
      color var(--color_main)
    }
  }

  &__link-title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_dark);
    transition 0.2s
    +below(768px) {
      font-size: 1em
      line-height: 20px;
    }
  }

  &__link-category {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_gray_dark);
    flex-shrink 0
    +below(768px) {
      font-size: 0.75em
      line-height: 16px;
    }
  }
}
</style>
